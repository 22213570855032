import * as React from "react";
import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import AdminAppContainer from "components/admin-app-container";
import { BoxedLoader } from "components/loader";
import PrivateRoute from "components/private-route";
import Login from "./pages/login";

const Payment = lazy(() => import("./pages/payment"));
const CsvBulkUpload = lazy(() => import("./pages/csv-bulk-upload"));
const Transactions = lazy(() => import("./pages/transactions"));
const TransactionDetails = lazy(() => import("./pages/transaction-details"));
const PaymentArrangements = lazy(() => import("./pages/payment-arrangements"));
const PaymentArrangement = lazy(() => import("./pages/payment-arrangement"));
const Sites = lazy(() => import("./pages/sites"));
const Site = lazy(() => import("./pages/site"));
const SiteEditHtmlContent = lazy(() => import("./pages/site/pages/edit-html-content"));
const SiteEditTheme = lazy(() => import("./pages/site/pages/edit-theme"));
const Reports = lazy(() => import("./pages/reports"));
const AuditInterrogation = lazy(() => import("./pages/audit-interrogation"));
const Users = lazy(() => import("./pages/users"));
const UserProfile = lazy(() => import("./pages/account/user-profile"));
const ChangePassword = lazy(() => import("./pages/account/change-password"));
const User = lazy(() => import("./pages/user"));
const About = lazy(() => import("./pages/about"));
const ForgotPassword = lazy(() => import("./pages/account/forgot-password"));
const ResetPassword = lazy(() => import("./pages/account/reset-password"));
const Security = lazy(() => import("./pages/security"));
const Error = lazy(() => import("pages/error"));
const NotFound = lazy(() => import("pages/not-found"));

const Manage = () => (
    <Switch>
        <Route exact path={["/login", "/account/forgot-password", "/account/:type(reset-password|new-user)"]}>
            <AdminAppContainer allowAnonymous maxWidth="md">
                <Suspense fallback={<BoxedLoader />}>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/account/forgot-password" component={ForgotPassword} />
                    <Route exact path="/account/:type(reset-password|new-user)" component={ResetPassword} />
                </Suspense>
            </AdminAppContainer>
        </Route>
        <Route>
            <AdminAppContainer maxWidth="lg">
                <Suspense fallback={<BoxedLoader />}>
                    <Switch>
                        <PrivateRoute
                            exact
                            path="/transactions/:id"
                            permission="canViewTransactions"
                            component={TransactionDetails}
                        />
                        <PrivateRoute exact path="/" permission="canViewTransactions" component={Transactions} />
                        <PrivateRoute
                            exact
                            path={["/phone-payment", "/input-credit-card-details", "/processing", "/receipt"]}
                            permission="canTakePhonePayments"
                            component={Payment}
                        />
                        <PrivateRoute
                            exact
                            path="/csv-bulk-upload"
                            permission="canTakePhonePayments"
                            component={CsvBulkUpload}
                        />
                        <PrivateRoute
                            exact
                            permission="canViewPaymentArrangements"
                            path="/future-payments/:id"
                            component={PaymentArrangement}
                        />
                        <PrivateRoute
                            exact
                            permission="canViewPaymentArrangements"
                            path="/future-payments"
                            component={PaymentArrangements}
                        />
                        <PrivateRoute exact permission="canManageAnyUsers" path="/users" component={Users} />
                        <PrivateRoute exact path="/users/:id" component={User} />
                        <PrivateRoute exact permission="canManageAnySites" path="/sites" component={Sites} />
                        <PrivateRoute exact path="/sites/:id" component={Site} />
                        <PrivateRoute
                            exact
                            path="/sites/:id/edit-html-content/:contentId"
                            component={SiteEditHtmlContent}
                        />
                        <PrivateRoute exact path="/sites/:id/edit-theme" component={SiteEditTheme} />
                        <PrivateRoute exact permission="canRunReports" path="/reports" component={Reports} />
                        <PrivateRoute
                            exact
                            permission="canViewAuditLogs"
                            path="/audit-logs"
                            component={AuditInterrogation}
                        />
                        <PrivateRoute exact path="/account" component={UserProfile} />
                        <PrivateRoute exact path="/account/change-password" component={ChangePassword} />
                        <PrivateRoute exact path="/about" component={About} />
                        <PrivateRoute
                            exact
                            path="/security"
                            permission={["canReadPermissionsConfig", "canManageAnyUsers"]}
                            component={Security}
                        />
                        <Route exact path="/error" component={Error} />
                        <Route path="*" component={NotFound} />
                    </Switch>
                </Suspense>
            </AdminAppContainer>
        </Route>
    </Switch>
);

export default Manage;
